<template>
	<div class="order-confirm" v-if="orderDetail">
		<div class="order-detail">
			<div class="user-address">
				<div class="address-info">
					<div class="address" @click="selectAddress">
						<span class="address-content">{{userAddress.address}}</span>
						<van-icon name="arrow" />
					</div>
					<div class="user-info" v-if="userAddress">
						<span class="user-name">{{userAddress.name}}</span>
						<span class="user-phone">{{userAddress.tel}}</span>
					</div>
					<div v-else>点击添加地址</div>
				</div>
			</div>

		</div>
		<div class="order-infomation" v-for="(item,index) in orderDetail.orders">
			<p>{{item.name}}</p>
			<div v-for="(items,indexs) in item.item">
				<van-card :price="items.price" :num="items.count" :title="items.name" :thumb="imageUrl + items.image">
				</van-card>
			</div>
			<div class="subtotalPrice">
				<p></p>
				<p>共:<span>{{item.item.length}}</span>件 小计:<span>{{item.prices.price}}</span></p>
			</div>
			<!-- 优惠券单元格 -->
			<van-cell title="优惠券" @click="showList(item)" is-link />
			<!-- 优惠券列表 -->
			<van-action-sheet v-model:show="showLists" close-on-click-action @cancel="onCancel">
				<van-checkbox-group v-model="checked" :max="1">
					<div v-for="item in coupons" class="coupon-list">
						<div class="coupon-item">
							<div class="coupon-left">
								<img :src="imageUrl +item.image" alt="" class="coupons-image">
								<div class="coupons-content">
									<span class="coupons-name" :class="item.is_use?'':'prohibit'">{{item.name}}</span>
									<span class="coupons-rate" :class="item.is_use?'':'prohibit'">
										<span v-if="item.type == 1">
											{{parseInt(item.rate)}}
											<span class="rate-company">折</span>
										</span>
										<span v-else>
											<span class="rate-company">￥</span>
											{{item.rate}}
										</span>
									</span>
									<span class="coupons-rule"
										:class="item.is_use?'':'prohibit'">使用规则：{{item.detail}}</span>
									<span
										class="coupons-time">有效期{{item.start_time.split(" ")[0]}}-{{item.end_time.split(" ")[0]}}</span>
								</div>
							</div>
							<van-checkbox :name="item.id" v-if="item.is_use" @click="seleteCoupons(item)">
							</van-checkbox>
						</div>
					</div>
				</van-checkbox-group>
			</van-action-sheet>
		</div>
		<div class="order-bottom">
			<div class="price-info">
				<span class="order-total">{{orderDetail.prices.amount}}</span>
				<span class="order-discount">已优惠{{orderDetail.prices.discount}}</span>
			</div>
			<div class="pay-btn" @click="pay">
				<span>立即支付</span>
			</div>
		</div>
		<van-action-sheet v-model:show="show" cancel-text="取消" close-on-click-action @cancel="onCancel">
			<div class="content">
				<van-address-list v-model="chosenAddressId" :list="addressList" default-tag-text="默认" @add="addAddress"
					@edit="onEdit" @select="toggleAddress" />
			</div>
		</van-action-sheet>
	</div>
</template>

<script crossorigin>
	import {
		getUserAddressList
	} from '../../api/user.js'
	import {
		orderInfoDetail,
		getBuyOrderDetail,
		getUserCoupon,
		submitOrder,
		getTN,
		buySubmitOrder,
		orderRecetive
	} from '../../api/order.js'
	import {
		ref
	} from 'vue';
	import {
		Toast
	} from 'vant';
	import axios from 'axios'
	import {
		getToken
	} from '../../utils/token.js'
	import config from '../../utils/config.js'
	export default {

		data() {
			return {
				orderDetail: '',
				imageUrl: this.url.imageUrl,
				addressList: '',
				show: false,
				chosenAddressId: '',
				addressList: '',
				userAddress: '',
				chosenCoupon: -1,
				coupons: '',
				checked: [],
				showLists: false,
				active: 0,
				orderId: '',
				carts: [],
				newDataArr: {
					sid: '',
					cart_id: ''
				},
				newsArrs: []
			}
		},
		computed: {
			discountPriceCacl() {
				console.log(this.orderDetail)
				return this.discountPrice
			}
		},
		mounted() {
			// console.log(this.$route.query.orderDataList)
			this.getUserAddressList()
			if (this.$route.query.orderDataList) {
				this.getOrderInfo()
			} else {
				this.getBuyOrderInfo()
			}
		},

		methods: {
			getTN(num) {
				let that = this
				getTN({
					'order_num': num
				}).then((res) => {
					console.log(res)
					upsdk.pluginReady(function() {
						upsdk.pay({
							tn: res.data.tn,
							success: function() {
								// 支付成功, 开发者执行后续操作。
								console.log('成功')
								that.$toast('支付成功')
								setTimeout(() => {
									that.$router.push({
										path: '/myOrders'
									})
								}, 1000)
							},
							fail: function(err) {
								// 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
								that.$toast(err.msg)
							}
						});
					});
				})
			},
			orderRecetive(num) {
				orderRecetive({
					'order_num': num
				}).then((res) => {
					// console.log(res.msg)
					this.$toast(res.msg)
					setTimeout(() => {
						this.$router.push({
							path: '/myOrders'
						})
					}, 1000)
				})
			},
			//支付 
			pay() {
				// console.log(this.newsArrs)
				let that = this
				let submitParams = {
					address_id: that.userAddress.id,
					carts: this.newsArrs,
					// coupon_id: that.orderId
				}
				let buySubmitParams = {
					address_id: that.userAddress.id,
					pid: that.$route.query.pid,
					union: that.$route.query.union,
					count: that.$route.query.count,
					coupon_id: that.orderId
				}
				if (that.$route.query.orderDataList) {
					const token = getToken();
					axios.post(config.server + 'order/submit', submitParams, {
						headers: {
							authorization: token
						}
					}).then((res) => {
						console.log(res)
						if (this.orderDetail.prices.amount == '￥0') {
							that.orderRecetive(res.data.data.order_num)
						} else {
							// console.log(res.data.data.order_num)
							that.getTN(res.data.data.order_num)
						}
					})
				} else {
					buySubmitOrder(buySubmitParams).then((res) => {
						console.log(res)
						// console.log(res.data.order_num)
						// console.log(this.orderDetail.prices.amount)
						if (this.orderDetail.prices.amount == '￥0') {
							that.orderRecetive(res.data.order_num)
						} else {
							that.getTN(res.data.order_num)
						}
					})
				}

			},
			//选择优惠券
			seleteCoupons(item) {
				console.log(item)
				this.orderId = item.id
				this.checked[0] = item.id
				this.showLists = false
				if (this.$route.query.orderDataList) {
					this.getOrderInfo(item.id)
				} else {
					this.getBuyOrderInfo(item.id)
				}
			},
			//获取用户优惠券  
			getUserCoupon(status) {
				// console.log(status)
				let params = {}
				if (status) {
					params.cart_id = status
				} else {
					params.pid = this.$route.query.pid
				}
				// console.log(params)
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				getUserCoupon(params).then((res) => {
					console.log(res)
					this.coupons = res.data
					if (!this.coupons) {
						this.showLists = false
						this.$toast('暂无优惠券')
					} else {
						this.showLists = true
					}
					// console.log(this.coupons)
					this.$toast.clear()
				})
			},
			showList(item) {
				console.log(item)
				this.getUserCoupon(item.cart_id)
			},
			onChange(index) {
				this.showLists = false
				this.chosenCoupon = index
			},
			onExchange(code) {
				this.coupons.push(coupon)
			},
			//编辑地址
			onEdit(item, index) {
				this.$router.push({
					path: '/addressEdit',
					query: {
						id: item.id
					}
				})
			},
			//选择地址
			toggleAddress(item, index) {
				this.userAddress = Object.assign({}, this.userAddress, item)
				this.show = false
			},
			//新增地址
			addAddress() {
				this.$router.push({
					path: '/addressEdit'
				})
			},
			selectAddress() {
				this.show = true
			},
			onCancel() {
				this.$toast('取消');
			},
			//获取地址列表
			getUserAddressList() {
				getUserAddressList().then((res) => {
					// console.log(res)
					let isDefaultNum = 0
					if (res.data) {
						this.addressList = res.data
						res.data.forEach((v, i) => {
							// console.log(v.isDefault)
							if (v.isDefault) {
								isDefaultNum++;
								this.chosenAddressId = v.id
								this.userAddress = v
							}
						})
					}
					if (isDefaultNum == 0) {
						this.chosenAddressId = res.data[0].id
						this.userAddress = res.data[0]
					}
				})
			},
			//获取立即购买订单信息
			getBuyOrderInfo(val) {
				// console.log(val)
				var params = {}
				params.pid = this.$route.query.pid
				params.union = this.$route.query.union,
					params.count = this.$route.query.count
				if (val) {
					params.coupon_id = val
				}
				if (this.$route.query.appoint_id && this.$route.query.appoint_date) {
					params.appoint_id = this.$route.query.appoint_id
					params.appoint_date = this.$route.query.appoint_date
				}
				getBuyOrderDetail(params).then((res) => {
					console.log(res)
					this.orderDetail = res.data
					this.totalPrice = res.data.prices.total
				})
			},
			//获取购物车订单信息
			getOrderInfo(val) {
				console.log(val)
				this.newDataArr.sid = this.$route.query.sid
				this.newDataArr.cart_id = this.$route.query.orderDataList
				this.carts.push(this.newDataArr)
				// let newsArrs = [];
				for (let i = 0; i < this.carts.length; i++) {
					if (this.newsArrs.indexOf(this.carts[i]) === -1) {
						this.newsArrs.push(this.carts[i]);
					}
				}
				console.log(this.newsArrs)
				let params = {}
				params.carts = this.newsArrs
				params.address_id = this.$route.query.address_id
				if (val) {
					this.newDataArr.coupon_id = val
				}
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				console.log(params)
				const token = getToken();
				axios.post(config.server + 'order/confirm', params, {
						headers: {
							authorization: token
						}
					})
					.then((res) => {
						if (res.data.code == 200) {
							console.log(res);
							this.orderDetail = res.data.data
						} else {
							this.$toast(res.data.msg)
						}
						this.$toast.clear()
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	}
</script>
<!--  -->
<style scoped lang="less">
	.order-confirm {
		background-color: #F6F6F6;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 100px;

		// min-height: 100%;
		.prohibit {
			color: #999999 !important;
		}

		::v-deep(.van-action-sheet) {

			max-height: 60%;
		}

		// 
		// ::v-deep(.van-coupon-list__exchange-bar) {
		// 	display: none;
		// }

		.coupon-list {
			padding: 15px;
			background-color: #F0F0F0;

			.coupon-item {
				background-color: #FFFFFF;
				width: 100%;
				border-radius: 8px;
				padding: 10px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.coupon-left {
					display: flex;
					justify-content: space-between;

					.coupons-image {
						width: 100px;
						height: 80px;
					}

					.coupons-content {
						display: flex;
						flex-direction: column;
						margin-left: 20px;

						.coupons-name {
							font-size: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}

						.coupons-rate {
							font-size: 24px;
							color: red;

							span {
								letter-spacing: 0;
							}

							.rate-company {
								font-size: 16px;
								font-weight: 400;
								letter-spacing: -3px;
							}
						}

						.coupons-rule {
							font-size: 12px;
							color: #666666;
						}

						.coupons-time {
							font-size: 12px;
							color: #999999;
						}
					}
				}

			}
		}

		.order-detail {
			padding: 0 15px;
			position: relative;
			background-image: linear-gradient(to bottom, rgb(255, 209, 0), rgb(255, 255, 255));
			height: 150px;
			box-sizing: border-box;
			width: 100%;
			margin-bottom: 10px;

			.user-address {
				position: absolute;
				top: 30px;
				background-color: #FFFFFF;
				border-radius: 10px;
				height: 115px;
				width: calc(100% - 30px);
				box-sizing: border-box;
				padding: 10px;

				.address-info {
					display: flex;
					flex-direction: column;

					.address {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.address-content {
							font-size: 18px;
							font-weight: bold;
						}
					}

					.user-info {
						font-size: 16px;
						color: #666666;
						margin-top: 15px;

						.user-name {
							margin-right: 15px;
						}
					}
				}
			}

		}

		.order-infomation {
			border-radius: 10px;
			background-color: #FFFFFF;
			box-sizing: border-box;
			width: calc(100% - 30px);
			padding: 15px;
			margin-bottom: 20px;

			.subtotalPrice {
				display: flex;
				justify-content: space-between;
				font-size: 14px;

				p {
					margin: 0;
					padding: 0 16px;
					color: #323233;

					span {
						color: red;
					}
				}
			}
		}


		.order-bottom {
			width: 90%;
			display: flex;
			align-items: center;
			position: fixed;
			bottom: 10px;
			margin: 0 auto;
			height: 44px;

			.price-info {
				height: 100%;
				display: flex;
				font-size: 18px;
				width: 70%;
				justify-content: space-around;
				background-color: #464847;
				color: #FFD96C;
				border-top-left-radius: 22px;
				border-bottom-left-radius: 22px;
				// padding: 15px 10px;
				padding-left: 20px;
				box-sizing: border-box;
				flex-direction: column;

				.order-discount {
					color: #999999;
					font-size: 12px;
				}
			}

			.pay-btn {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgb(255, 209, 0);
				border-top-right-radius: 22px;
				border-bottom-right-radius: 22px;
				font-size: 18px;
				font-weight: bold;
				box-sizing: border-box;
				padding: 15px 10px;
				width: 30%;
			}
		}

		.van-address-list {
			padding: 0;
		}
	}
</style>
